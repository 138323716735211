import React, { useState } from "react"
import styled from "styled-components"
import Steps from "./Steps"
import StepContentPanel from "./StepContentPanel"
import items from "./panel-items"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const StepRow = () => {
  const [active, setActive] = useState(0)
  return (
    <Container>
      <Steps steps={items} active={active} onChange={setActive} />
      <StepContentPanel items={items} active={active} setActive={setActive} />
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div``

export default StepRow
