import React from "react"
import styled from "styled-components"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const InfoBlock = ({ title, children, id }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Container>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: white;
  overflow: hidden;
`

const Title = styled.h3`
  background: ${({ theme }) => theme.colors.midnight["100"]};
  text-align: center;
  color: white;
  padding: 20px;
  font-weight: 400;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 20px;
  }
`

const Content = styled.div`
  padding: 20px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 16px;
  }
`

export default InfoBlock
