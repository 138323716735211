import React from "react"
import styled from "styled-components"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const PanelSlider = ({ children, active }) => {
  return (
    <Container>
      <Slider active={active} length={children.length}>
        {children}
      </Slider>
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  background: white;
  overflow: hidden;
`
const Slider = styled.div`
  width: ${({ length }) => length * 100 + "%"};
  display: grid;
  grid-template-columns: repeat(${({ length }) => length}, 1fr);
  transition: 0.2s all ease-in-out;
  transform: translateX(
    ${({ length, active }) => (-100 / length) * active + "%"}
  );
`

export default PanelSlider
