import React from "react"
import styled from "styled-components"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Step = ({ SvgComponent, label, active, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Circle active={active}>
        <SvgIcon
          size="lg"
          SvgComponent={SvgComponent}
          hue="white"
          shade="000"
        />
      </Circle>
      <Label dangerouslySetInnerHTML={{ __html: label }} />
    </Container>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  width: 150px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100px;
  }
`
const Circle = styled.div`
  background: ${({ theme, active }) =>
    active ? "red" : theme.colors.midnight["100"]};
  width: 100px;
  height: 100px;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    margin-bottom: 20px;
    width: 50px;
    height: 50px;
  }
`

const Label = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 13px;
    span {
      display: none;
    }
  }
`

export default Step
