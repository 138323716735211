import React from "react"
import styled from "styled-components"
import Inner from "../../zzz/layout/pageInner/Inner"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Terms = () => {
  return (
    <Container>
      <Inner>
        <Title>Terms & Conditions:</Title>
        <List>
          <li>
            The Mattress Warehouse Accounts are provided by FeverTree Finance
            (Pty) Ltd a registered credit provider (NCRCP6072).
          </li>
          <li> You have 24 months to settle your bill.</li>
          <li>
            You need to be between the ages of 18 and 65, earn a minimum of R5
            000 per month and be a responsible credit user.
          </li>
          <li> Subject to affordability.</li>
          <li> SA citizens only.</li>
        </List>
      </Inner>
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  padding: 60px 0;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 30px 0;
  }
`
const Title = styled.h3`
  font-size: 32px;
  margin-bottom: 15px;
`
const List = styled.ul`
  list-style: disc;
  margin-left: 25px;
  font-size: 16px;
  font-size: 16px;
`

export default Terms
