import React from "react"
import styled from "styled-components"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const SpendCredit = ({ setActive, idx }) => {
  return (
    <Container>
      <Text>You have completed your credit application!</Text>
      <Text>
        Once you have received confirmation of credit approval, continue placing
        an order on our website and select the{" "}
        <span className="bold">Credit Account</span> payment option during
        checkout - or use your credit account at any of our nationwide stores!
      </Text>
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
  font-size: 18px;
`
export const Text = styled.p`
  font-size: 18px;
  margin: 20px;
  text-align: center;
  .bold {
    font-weight: bold;
  }
`

export default SpendCredit
