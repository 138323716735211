import React from "react"
import styled from "styled-components"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Raised = ({ children }) => {
  return <Container>{children}</Container>
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
  overflow: hidden;
  background: white;
`

export default Raised
