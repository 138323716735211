import React from "react"
import styled from "styled-components"
import Step from "./Step"
import Inner from "../../zzz/layout/pageInner/Inner"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Steps = ({ steps = [], active, onChange }) => {
  return (
    <Container>
      <Inner>
        <Row>
          {steps.map(({ label, SvgComponent }, i) => (
            <Step
              label={label}
              SvgComponent={SvgComponent}
              active={active === i}
              key={i}
              onClick={() => onChange(i)}
            />
          ))}
        </Row>
      </Inner>
    </Container>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  padding: 60px 0;
  transition: 0.2s all ease-in-out;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 20px 0;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;

  &:before {
    content: "";
    width: 80%;
    position: absolute;
    border: 1px solid #e0e0e0;
    top: 50px;
    z-index: 0;

    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      top: 25px;
    }
  }
`

export default Steps
