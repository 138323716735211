import React from "react"

const IconAddressCard = ({ fill }) => (
  <svg width="16" height="13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.125.625c.965 0 1.75.785 1.75 1.75v8.75c0 .965-.785 1.75-1.75 1.75H1.875c-.965 0-1.75-.785-1.75-1.75v-8.75c0-.965.785-1.75 1.75-1.75h12.25zm.875 10.5v-8.75a.878.878 0 00-.875-.875H1.875A.878.878 0 001 2.375v8.75c0 .481.394.875.875.875h12.25a.878.878 0 00.875-.875zm-1.969-3.5a.22.22 0 01.219.219v.437a.22.22 0 01-.219.219H9.97a.22.22 0 01-.219-.219v-.437a.22.22 0 01.219-.219h3.062zm0-1.75a.22.22 0 01.219.219v.437a.22.22 0 01-.219.219H9.97a.22.22 0 01-.219-.219v-.437a.22.22 0 01.219-.219h3.062zm0-1.75a.22.22 0 01.219.219v.437a.22.22 0 01-.219.219H9.97a.22.22 0 01-.219-.219v-.437a.22.22 0 01.219-.219h3.062zm-7.219 3.5a2.187 2.187 0 110-4.374 2.187 2.187 0 010 4.374zm0-3.5a1.313 1.313 0 10.001 2.626 1.313 1.313 0 000-2.626zm1.28 3.938c.577 0 1.14.257 1.477.749.197.292.311.645.311 1.025v.632a.22.22 0 01-.218.219h-.438a.22.22 0 01-.219-.22v-.63a.96.96 0 00-.164-.537c-.153-.224-.432-.36-.746-.36-.342 0-.572.19-1.28.19-.71 0-.94-.19-1.28-.19-.314 0-.593.133-.746.36a.96.96 0 00-.164.536v.632a.22.22 0 01-.219.219H2.97a.22.22 0 01-.219-.22v-.63c0-.381.115-.734.312-1.026.328-.492.891-.75 1.47-.75.534 0 .668.192 1.28.192.613 0 .747-.191 1.28-.191z"
      fill={fill}
    />
  </svg>
)

export default IconAddressCard
