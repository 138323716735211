import React from "react"
import styled from "styled-components"
import { Button } from "./GetStarted"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ArrowRight from "../../zzz/atoms/icons/files/arrow-right.jsx"
import { postFeverTreeApplied } from "../../lib/woocommApi"
import { getUser } from "../../services/auth"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const CreditApproval = ({ setActive, idx }) => {
  const sendAppliedAndContinue = () => {
    const { token } = getUser()
    postFeverTreeApplied(token).catch(() => {})
    setActive(idx + 1)
  }
  return (
    <Container>
      <iframe
        frameBorder="0"
        height="600px"
        scrolling="yes"
        src="https://www.ftapp.co.za/app/MA?source=MAOnline"
        width="100%"
      ></iframe>
      <Text>Click DONE once your application above has been submitted</Text>
      <Button fluid onClick={sendAppliedAndContinue}>
        <div>DONE</div>
        <SvgIcon size="lg" SvgComponent={ArrowRight} hue="white" shade="000" />
      </Button>
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`
export const Text = styled.p`
  font-size: 18px;
  margin: 20px;
  text-align: center;
`

export default CreditApproval
