import React from "react"
import GetStarted from "./GetStarted"
import PreApproval from "./PreApproval"
import CreditApproval from "./CreditApproval"
import SpendCredit from "./SpendCredit"
import AddressCard from "../../zzz/atoms/icons/files/address-card.jsx"
import Mobile from "../../zzz/atoms/icons/files/mobile.jsx"
import PenSquare from "../../zzz/atoms/icons/files/pen-square.jsx"
import ShoppingCart from "../../zzz/atoms/icons/files/shopping-cart.jsx"

// Pass on setActive and idx to components
// so that they can control active element
const withProps = (Comp) => (props) => <Comp {...props} />

const items = [
  {
    label: "Get Started",
    SvgComponent: AddressCard,
    panelTitle: "Apply for your Mattress Warehouse Credit Account",
    Component: withProps(GetStarted),
  },
  // a <span> will be hidden on mobile
  {
    label: "Step 1<span>: Pre-Approval</span>",
    SvgComponent: Mobile,
    panelTitle: "Find out if you qualify within 30 seconds",
    Component: withProps(PreApproval),
  },
  {
    label: "Step 2<span>: Credit Application</span>",
    SvgComponent: PenSquare,
    panelTitle: "Apply",
    Component: withProps(CreditApproval),
  },
  {
    label: "Step 3<span>: Spend Credit</span>",
    SvgComponent: ShoppingCart,
    panelTitle: "You've completed your application!",
    Component: withProps(SpendCredit),
  },
]

export default items
