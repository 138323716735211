import React from "react"
import styled from "styled-components"
import { Button, Text } from "./GetStarted"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ArrowRight from "../../zzz/atoms/icons/files/arrow-right.jsx"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const PreApproval = ({ setActive, idx }) => {
  return (
    <Container>
      <Text>
        Simply SMS{" "}
        <div className="highlite">RSA ID Number*Gross Monthly Income</div> to{" "}
        <div className="bold">45180</div>
        <br />
        *Please remember to separate the values in the SMS with an asterisk (*)
        <br />
        SMS's costs R1.50 & free SMS's does not apply
        <br />
        Once you have received your pre-approved confirmation, click NEXT
      </Text>

      <Button onClick={() => setActive(idx + 1)}>
        <div>NEXT</div>
        <SvgIcon size="lg" SvgComponent={ArrowRight} hue="white" shade="000" />
      </Button>
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
`

export default PreApproval
