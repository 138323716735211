import React from "react"
import styled from "styled-components"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import { formatPhoneToLink, formatEmailToLink } from "../../lib/util"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Contact = ({ SvgComponent, type, value }) => {
  const link =
    type === "tel" ? formatPhoneToLink(value) : formatEmailToLink(value)

  return (
    <Container>
      <SvgIcon hue="ocean" shade="040" size="md" SvgComponent={SvgComponent} />
      <div dangerouslySetInnerHTML={{ __html: link }} />
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;

  &:hover {
    div {
      transform: translateX(3px);
    }
  }
  div {
    transition: 0.2s all ease-in-out;
    word-break: break-all;
  }
`

export default Contact
