import React from "react"
import Layout from "../zzz/layout"
import { useStaticQuery, graphql } from "gatsby"
import Heading from "../zzz/organisms/heading"
import { getImage } from "../lib/util"
import StepRow from "../components/ApplyForCredit/StepRow"
import Terms from "../components/ApplyForCredit/Terms"
import SEO from "../components/SEO/seo"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const ApplyForCreditPage = ({ location }) => {
  const {
    localWpGraphQlPages: {
      seo,
      title,
      pageId,
      heading: { subTitle },
      featuredImage,
    },
  } = useStaticQuery(PAGE_QUERY)

  return (
    <Layout location={location}>
      <SEO title={title} location={location} post_id={pageId} seo={seo} />
      <Heading
        location={location}
        subTitle={subTitle}
        image={getImage(featuredImage)}
      />
      <div>
        <StepRow />
        <Terms />
      </div>
    </Layout>
  )
}

// ==================
// 	👨‍💻👨‍💻 QUERY 👨‍💻👨‍💻
// ==================

const PAGE_QUERY = graphql`
  {
    localWpGraphQlPages(slug: { eq: "apply-for-credit" }) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      title
      pageId
      heading {
        subTitle
      }
      featuredImage {
        sourceUrl
        mediaItemId
        imageFile {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`

export default ApplyForCreditPage
