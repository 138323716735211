import React from "react"
import styled from "styled-components"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ArrowRight from "../../zzz/atoms/icons/files/arrow-right.jsx"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const GetStarted = ({ setActive, idx }) => {
  return (
    <Container>
      <Text>
        We have an easy 3 step process to get you started and qualified for a
        credit account.
      </Text>
      <Button onClick={() => setActive(idx + 1)}>
        <div>GET STARTED</div>
        <SvgIcon size="lg" SvgComponent={ArrowRight} hue="white" shade="000" />
      </Button>
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  padding: 40px 0;
  text-align: center;
`

export const Text = styled.p`
  font-size: 18px;
  margin-bottom: 40px;
  .highlite {
    color: #fd3237;
  }
  .bold {
    font-weight: bold;
    font-size: larger;
  }
`

export const Button = styled.button`
  color: white;
  background-color: #fd3237;
  border-radius: 3px;
  border: none;
  display: flex;
  flex-direction: space-between;
  padding: 0;
  overflow: hidden;
  align-items: center;
  width: ${({ fluid }) => (fluid ? "100%" : "fit-content")};
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  margin: 0 auto;

  &:hover {
    transform: translateX(3px);
  }

  div {
    width: 100%;
    font-size: 18px;
    padding: 0 20px;
  }

  > span.icon {
    background-color: #dc262a;
  }
`

export default GetStarted
